const ROADMAP_DATA = [
    {
      id: 1,
      title: "Phase 1a - Cell Stage",
      description: "Initial Funding PreRelease",
      image: "require(../../assets/phase-imgs/cell-stage.jpg)",
      quantity: 100,
      writeup: [
        {
          "class" : "basicTopic1",
          "content" : "This will be the Initial Funding and Base Metric Test Line of Lomb Co Products:",
        },
        {
          "class" : "basicTopic1",
          "content" : "Seed Stage, 1a",
        },
        {
          "class" : "basicLine1",
          "content" : "This first line of sales will ensure customer interaction.",
        },
        {
          "class" : "basicLine1",
          "content" : "Accounting for the use capabilities throughout our System.",
        },
        {
          "class" : "basicLine2",
          "content" : "Including:",
        },
        {
          "class" : "basicLine1",
          "content" : "Sign Up Thru Purchase, Account Retrievability, Updating & Recovering Credentials, Current Metric Tracking, etc.",
        },
        
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : "This initial stage will ensure Lomb Co. Members recieve reliable User Interfaces for effective daily interaction.",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : "A purchase of Line 1a will Secure Member Functional about All Data Records.  Used to then Deliver our Future Aspirations w/ Greater Security in a Known & Reliable Manner.",
        },
        {
          "class" : "basicLine2",
          "content" : "If you appreciate: ",
        },
        {
          "class" : "basicLine1",
          "content" : "Creativity and Art, Education made Simple, Promoting Healthy Culture, Elevating Common Sense, etc.",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : "Please purchase to help fund Our initiative (in)&directly.",
        },
        {
          "class" : "basicLine1",
          "content" : "In exchange you'll obtain Better Interactions DAILY and the Secrets of the Universe... Don't Miss Out!",
        },
      ]
    },
    {
      id: 2,
      title: "Phase 1b - Predator Stage",
      description: "2nd Collection /w Initial Member Feedback Loop",
      image: "../../assets/phase-imgs/predator-stage.jpg",
      quantity: 100,
      writeup: [
        {
          "class" : "basicTopic1",
          "content" : "This 2nd collection further develops the initial Lomb Co. product.  Now displaying Daily Member Interaction & Usage Features.",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : "Our initial Funding Stage 1a established a working model.",
        },
        {
          "class" : "basicTopic1",
          "content" : "Now in 1b, we elaborate that model with inclusive daily information and interaction features.",
        },
        {
          "class" : "basicLine1",
          "content" : "Goal:  Improving every Lomb Co. Member's daily habits.",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine2",
          "content" : "Interactions will not be demanding or laborous .",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicTopic1",
          "content" : "The Lomb Co. aim is to provide a digital location to briefly escape or plan your day.  All while effectively and readily elevating your mind.",
        },
        {
          "class" : "basicLine1",
          "content" : "The Lomb Co. initiative is to  Promote continuous use with a return simply aimed toward forming a Better You.",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine3",
          "content" : "At stage 1a we found our footing.",
        },
        {
          "class" : "basicLine3",
          "content" : "Now with 1b we turn our eyes toward the Aether.",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : "Aesthetically the 2nd collection will also be of unique design.  Enabling Appreciators to expand their Collection.",
        },
      ]
    },
    {
      id: 3,
      title: "Phase 25 - Cohesion Stage",
      description: "First Scaled Release Proven & Tested",
      image: "../../assets/phase-imgs/cohesion-stage.jpg",
      quantity: 200,
      writeup: [
       
        {
          "class" : "basicLine1",
          "content" : "The Cohesion Stage 25 will be the first Scaled Release",
        }, 
        {
          "class" : "basicTopic1",
          "content" : "Proven & Tested /w Working Demo: Established",
        },
        {
          "class" : "basicTopic1",
          "content" : "The Working Demo Establishes New Features with Feedback Loops for a more Involved Member Experience",
        }, 
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine2",
          "content" : "This is where the Real Buzz starts, and the best part is:",
        }, 
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : "With even minimal effort or contribution",
        }, 
        {
          "class" : "basicLine1",
          "content" : "Online or about other Lomb Co. Members",
        }, 
        {
          "class" : "basicLine1",
          "content" : "These benefits will remain consistently yours",
        }, 
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine3",
          "content" : "The aim has shifted; into high gear and the Convesation is Becoming",
        }, 
        {
          "class" : "basicLine3",
          "content" : " Whatever, or so 90s, etc.",
        }, 
        {
          "class" : "basicLine2",
          "content" : "Time is a little less relevant.  Everything is Out Dated.",
        }, 
        {
          "class" : "basicLine2",
          "content" : "The Predicate starts to become a little less clear... Suddenly the Windows are Open Once Again.",
        }, 
      ]
    },
    {
      id: 4,
      title: "Phase 35a - Family Stage",
      description: "Expansion Wave for Live Current Growth with Additional Elementary Features to Enhance Relative Buzz",
      image: "../../assets/phase-imgs/family-stage.jpg",
      quantity: 300,
      writeup: [
        {
          "class" : "basicTopic1",
          "content" : "It wouldn't be appropriate to quote this far out from initial launch, check back as Cohesion Stage reaches fruition.",
        }, 
        {
          "class" : "basicLine2",
          "content" :"There are many ideas on the table but for proper order are dependent upon bayesian forethought",
        }, 
        {
          "class" : "basicLine2",
          "content" :"To assure the best product we will be moving iteratively about a lean model to meet the relative demand & active uses our Members promote within this System",
        }, 
      ]
    },
  ];
  
  export default ROADMAP_DATA;